import React, { useState, useEffect, useRef } from 'react'
import { Table, Modal, Button, Navbar, Nav, Tabs, Tab, Form, Col, Alert } from 'react-bootstrap'

import { useApi } from '../api/WildduckApi'

export default function Users() {
    const { listUsers, getAddresses, createUser, updateUser, deleteUser, addAddress, deleteAddress, requestUserInfo } = useApi();

    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [showDelete, setShowDelete] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const passRef = useRef();
    const passRepRef = useRef();
    const aliasRef = useRef();

    useEffect(() => { 
        loadUsers();
    }, []);

    const loadUsers = () => {
        setUsers([]);
        listUsers().then(result => {
            let usrs = result.data.results.map(user => {
                return {
                    id: user.id,
                    username: user.username,
                    name: user.name,
                    address: user.address,
                    activated: user.activated,
                    sendOnly: false
                }
            });

            setUsers(usrs);
        })
    }

    const newUser = () => {
        setSelectedUser({ id: -1, addresses: [], activated: true, name: '', username: '' });
        setShowModal(true);
    }

    const generateDefaultAddress = () => {
        if (!selectedUser.addresses.length && selectedUser.username) {
            setSelectedUser({...selectedUser, addresses: [{
                id: -1,
                address: `${selectedUser.username}@easyrd.de`,
                main: true,
                deleted: false
            }]})
        }
    }

    const editUser = async (id) => {
        const u = users.find(u => u.id === id);
        if (!u) return;

        await getAddresses(u.id).then(result => {
            u.addresses = result.data.results.map(a => {
                return {
                    id: a.id,
                    address: a.address,
                    main: a.main,
                    deleted: false
                }
            })
        })

        await requestUserInfo(u.id).then(r => {
            u.sendOnly = r.data.disabledScopes?.length > 0 || false;
        })

        setSelectedUser(u);
        setShowModal(true);
    }

    const saveChanges = () => {
        if (selectedUser.id !== -1) {
            const data = {
                name: selectedUser.name,
                disabledScopes: [],
                disabled: !selectedUser.activated
            }

            updateUser(selectedUser.id, data)

            const newAddresses = selectedUser.addresses.filter(a => a.id === -1);
            newAddresses.forEach(a => {
                addAddress(selectedUser.id, a.address, a.main)
            })

            const deleted = selectedUser.addresses.filter(a => a.deleted);
            deleted.forEach(a => {
                deleteAddress(selectedUser.id, a.id);
            })

            setShowModal(false);
        }
        else {
            if (passRef.current.value !== passRepRef.current.value) return;

            const data = {
                name: selectedUser.name,
                username: selectedUser.username,
                disabledScopes: selectedUser.sendOnly ? ['imap', 'pop3'] : [],
                password: passRef.current.value,
                address: selectedUser.addresses.find(a => a.main === true).address
            }

            var i = selectedUser.addresses.findIndex(a => a.main === true);
            selectedUser.addresses.splice(i, 1);

            createUser(data).then(result => {
                if (selectedUser.addresses.length) {
                    setSelectedUser({...selectedUser, id: result.data.id});

                    selectedUser.addresses.map(a => {
                        addAddress(result.data.id, a.address, a.main);
                    });
                }
                
                loadUsers();
                setShowModal(false);
            });
        }
    }

    const deleteSelectedUser = () => {
        let user = users.findIndex(u => u.id === selectedUser.id);
        let usr = [...users];
        usr.splice(user, 1)

        setUsers(usr);
        deleteUser(selectedUser.id);
        setShowDelete(false);
    }

    const addAlias = () => {
        if (!aliasRef.current.value) return

        setSelectedUser({...selectedUser, addresses: [...selectedUser.addresses, { id: -1, address: aliasRef.current.value, main: false, deleted: false }]})
        aliasRef.current.value = '';
    }

    const removeAddress = (id) => {
        const addresses = [...selectedUser.addresses];
        const index = addresses.findIndex(a => a.id === id);
        const add = addresses[index];
        add.deleted = true;

        addresses.splice(index, 1, add);

        setSelectedUser({...selectedUser, addresses: [...addresses]})
    }

    return (
        <>
            <Navbar bg="light" expand="lg" className="mb-1">
                <Nav className="mr-auto">
                    <Button variant="outline-secondary" onClick={newUser}>+</Button>
                </Nav>
            </Navbar>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Aktiv</th>
                        <th>Benutzername</th>
                        <th>Name</th>
                        <th>Hauptaddresse</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.length &&
                        users.map(u => {
                            return (
                                <tr key={u.id} style={{ cursor: 'pointer' }} onClick={ () => editUser(u.id) }>
                                    <td>
                                        <input type="checkbox" checked={u.activated} disabled />
                                    </td>
                                    <td>
                                        {u.username}
                                    </td>
                                    <td>
                                        {u.name}
                                    </td>
                                    <td>
                                        {u.address}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            { 
                selectedUser &&
                <>
                <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`${selectedUser.name} (${selectedUser.username})`}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '70vh', overflowY: 'auto' }} >
                        <Tabs>
                            <Tab eventKey="main" title="Haupteinstellungen">
                                <Form className="mt-2 w-100">
                                    <Form.Row className="ml-2">
                                        <Form.Group as={Col}>
                                            <Form.Label>Aktiv</Form.Label>
                                            <Form.Control className="w-auto" style={{ marginTop: '-5px' }} type="checkbox" checked={selectedUser.activated} onChange={e => setSelectedUser({...selectedUser, activated: e.target.checked })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nur senden</Form.Label>
                                            <Form.Control className="w-auto" style={{ marginTop: '-5px' }} type="checkbox" checked={selectedUser.sendOnly} onChange={e => setSelectedUser({...selectedUser, sendOnly: e.target.checked})} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="ml-2">
                                        <Form.Group as={Col}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control value={selectedUser.name} onChange={e => setSelectedUser({...selectedUser, name: e.target.value})} />
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Benutzername</Form.Label>
                                            <Form.Control value={selectedUser.username} onChange={e => setSelectedUser({...selectedUser, username: e.target.value})} onBlur={generateDefaultAddress} disabled={selectedUser.id !== -1} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className="ml-2">
                                        <Form.Group as={Col}>
                                            <Form.Label>Passwort</Form.Label>
                                            <Form.Control type="password" ref={passRef} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Passwort (Wiederholung)</Form.Label>
                                            <Form.Control type="password" ref={passRepRef} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className="ml-2">
                                        <Form.Group as={Col}>
                                            <Form.Label>Hauptadresse</Form.Label>
                                            <Form.Control as="select">
                                                {
                                                    selectedUser.addresses.filter(a => a.deleted === false).map(a => {
                                                        return <option key={a.id} selected={a.main} data-id={a.id}>{ a.address }</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </Tab>
                            <Tab eventKey="aliases" title="Alias">
                                <Table bordered hover className="mt-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Alias</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedUser.addresses.filter(a => a.deleted === false).map(a => {
                                                return (
                                                    <tr key={a.id}>
                                                        <td>{ a.address }</td>
                                                        <td style={{ width: '10%' }}>
                                                            <Button variant="danger" className="w-100" onClick={() => removeAddress(a.id) }>-</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td>
                                                <Form.Control ref={aliasRef} />
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                <Button variant="success" className="w-100" onClick={addAlias}>+</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Tab>
                        </Tabs>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={() => { setShowModal(false); setShowDelete(true); }}>Löschen</Button>
                        <Button variant="secondary" onClick={ () => setShowModal(false)}>Schließen</Button>
                        <Button onClick={ saveChanges }>Speichern</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDelete} onHide={() => setShowDelete(false)} backdrop="static" centered>
                    <Modal.Body as={Alert} variant="warning" className="m-0">
                        <h4 className="alert-heading">{`${selectedUser.name} löschen?`}</h4>
                        <p>Wenn Sie diesen Benutzer löschen, werden auch seine Postfächer und alle darin gespeicherten Emails gelöscht.</p>
                        <p><b>Dieser Vorgang kann nicht rückgängig gemacht werden!</b></p>
                        <hr />
                        <p className="d-flex justify-content-end m-0">
                            <Button variant="secondary-outline" onClick={() => { setShowDelete(false); setShowModal(true); }}>Abbrechen</Button>
                            <Button variant="danger" onClick={deleteSelectedUser}>Löschen</Button>
                        </p>
                    </Modal.Body>
                </Modal>
                </>
            }
        </>
    )
}

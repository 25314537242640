import { Container } from 'react-bootstrap'
import { AuthProvider} from '../contexts/AuthContext'
import Login from './Login'
import Dashboard from './Dashboard'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

function App() {

  return (
    <Container className="d-flex" style={{ minHeight: '100vh', minWidth: '100vw' }}>
        <Router>
          <AuthProvider>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </AuthProvider>
        </Router>
      </Container>
  );
  
}

export default App;

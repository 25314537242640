import React from 'react'
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/AuthContext'
import logo from '../assets/img/logo.png'
import { Link, Switch, Route } from 'react-router-dom'

import Users from './Users'
import DKIM from './DKIM'

export default function Dashboard() {
    const { currentUser, logout } = useAuth();
    const history = useHistory();

    const doLogout = () => {
        logout();
        history.push('/login');
    }

    return (
        <>
            <div id="left" className="flex-grow-1 d-flex flex-column" style={{ marginLeft: '-15px', padding: '15px', maxWidth: '250px', backgroundColor: '#1a1a1a' }}>
                <Link to="/"><img width="220px" src={ logo } alt="logo" /></Link>

                <h4 className="mt-2 pb-0 mb-0" style={{ color: '#fff' }}>{ currentUser.name }</h4>
                <small className="w-100 mb-3" style={{ color: '#fff' }}>({ currentUser.username }) <div style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline-block' }} onClick={ doLogout }>Abmelden</div></small>
                <Link to="/users">Benutzer</Link>
                <Link to="/dkim">DKIM</Link>
            </div>
            <div id="main" className="flex-grow-1" style={{ paddingLeft: '15px', paddingTop: '15px' }}>
                <Switch>
                    <Route path="/dkim" component={ DKIM } />
                    <Route path="/users" component={ Users } />
                </Switch>
            </div>
        </>
    )
}

import axios from 'axios'

export function useApi() {
    return WildduckApi();
}

export function WildduckApi() {

    const apiUrl = "https://mailadmin.easyrd.de/api";
    const accessHeader = {
        headers: {
            'X-Access-Token': process.env.REACT_APP_ACCESS_TOKEN
        }
    }

    const auth = (username, password) => {
        const data = {
            username: username,
            password: password,
            scope: 'master',
            token: true
        };

        return axios.post(`${apiUrl}/authenticate`, data, accessHeader)
    }

    const logout = (token) => {
        axios.delete(`${apiUrl}/authenticate`, accessHeader);
    }

    const requestUserInfo = (id) => {
        return axios.get(`${apiUrl}/users/${id}`, accessHeader)
    }

    const listUsers = () => {
        return axios.get(`${apiUrl}/users`, accessHeader)
    }

    const getAddresses = (uid) => {
        return axios.get(`${apiUrl}/users/${uid}/addresses`, accessHeader)
    }

    const createUser = (data) => {
        return axios.post(`${apiUrl}/users`, data, accessHeader);
    }

    const updateUser = (uid, data) => {
        return axios.put(`${apiUrl}/users/${uid}`, data, accessHeader);
    }

    const deleteUser = (uid) => {
        return axios.delete(`${apiUrl}/users/${uid}`, accessHeader);
    }

    const addAddress = (uid, address, main = false) => {
        return axios.post(`${apiUrl}/users/${uid}/addresses`, { address: address, main: main}, accessHeader);
    }

    const deleteAddress = (uid, id) => {
        return axios.delete(`${apiUrl}/users/${uid}/addresses/${id}`, accessHeader);
    }

    const listDKIM = () => {
        return axios.get(`${apiUrl}/dkim`, accessHeader);
    }

    const getDKIMInfo = (id) => {
        return axios.get(`${apiUrl}/dkim/${id}`, accessHeader);
    }

    const createUpdateDKIM = (data) => {
        return axios.post(`${apiUrl}/dkim`, data, accessHeader);
    }

    const deleteDKIM = (id) => {
        return axios.delete(`${apiUrl}/dkim/${id}`, accessHeader);
    }

    return { auth, logout, requestUserInfo, listUsers, getAddresses, createUser, updateUser, deleteUser, addAddress, deleteAddress, listDKIM, getDKIMInfo, createUpdateDKIM, deleteDKIM }
}

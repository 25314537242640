import React, { useRef } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/AuthContext'
import '../assets/css/main.css'

export default function Login() {
    const { login } = useAuth();
    const userRef = useRef();
    const passRef = useRef();
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        await login(userRef.current.value, passRef.current.value);
        history.push('/');
    }

    return (
        <div className='d-flex align-items-center justify-content-center bg' style={{ width: '100vw', height: '100vh', marginLeft: '-15px', marginRight: '-15px'}}>
            <div className="w-100" style={{ maxWidth: '400px' }}>
                <h3 className="text-center mb-3" style={{ color: '#fff' }}>Mailserver Admin</h3>
                <Card>
                    <Card.Body>
                        <h4 className="text-center mb-4">Anmeldung</h4>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="user">
                                <Form.Label>Benutzername</Form.Label>
                                <Form.Control type="text" required ref={userRef} />
                            </Form.Group>

                            <Form.Group id="pass">
                                <Form.Label>Passwort</Form.Label>
                                <Form.Control type="password" required ref={passRef} />
                            </Form.Group>

                            <Button type="submit" className="w-100">Anmelden</Button>
                        </Form>
                    </Card.Body>
                </Card>

                <div className="credits">
                    Photo by <a href="https://unsplash.com/@jordanharrison?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jordan Harrison</a> on <a href="https://unsplash.com/s/photos/server?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </div>
            </div>
        </div>
    )
}

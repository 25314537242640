import React, { useState, useEffect, useRef } from 'react'
import { Table, Navbar, Nav, Button, Modal, Alert, Form, Col, Row, TextArea } from 'react-bootstrap'

import { useApi } from '../api/WildduckApi'

export default function DKIM() {

    const [dkim, setDKIM] = useState([]);
    const [selected, setSelected] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const { listDKIM, getDKIMInfo, createUpdateDKIM, deleteDKIM: api_deleteDKIM } = useApi(); 

    const domainRef = useRef();
    const selectorRef = useRef();

    useEffect(() => {
        listDKIM().then(result => {
            setDKIM(result.data.results);
        });
    }, [])

    const newDomain = () => {
        setSelected({
            id: -1,
            domain: '',
            selector: '',
            dnsTxt: {
                name: '',
                value: ''
            }
        });

        setShowModal(true);
    }

    const editDomain = (id) => {
        getDKIMInfo(id).then(result => {
            setSelected(result.data);
            setShowModal(true);
        })
    }

    const saveChanges = () => {
        createUpdateDKIM({
            domain: selected.domain,
            selector: selected.selector
        }).then(result => {
            let newDKIM = {
                id: result.data.id,
                domain: result.data.domain,
                selector: result.data.selector,
                fingerprint: result.data.fingerprint,
                publicKey: result.data.publicKey,
                dnsTxt: result.data.dnsTxt
            }

            setSelected(newDKIM);
            setDKIM([...dkim, newDKIM])
        })
    }

    const deleteDKIM = () => {
        api_deleteDKIM(selected.id).then(result => {
            let i = dkim.findIndex(d => d.id === selected.id);
            let tmp = [...dkim];
            tmp.splice(i, 1)
            setDKIM(tmp);

            setSelected(null);

            setShowDelete(false);
        })
    }

    const updateDNSText = e => {
        setSelected({...selected, dnsTxt: { name: `${selectorRef.current.value}._domainkey.${domainRef.current.value}` }});
    }

    return (
        <>
            <Navbar bg="light" expand="lg" className="mb-1">
                <Nav className="mr-auto">
                    <Button variant="outline-secondary" onClick={newDomain}>+</Button>
                </Nav>
            </Navbar>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Domain</th>
                        <th>Selector</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dkim.length > 0 &&
                        dkim.map(d => {
                            return (
                                <tr key={d.id} style={{ cursor: 'pointer' }} onClick={ () => editDomain(d.id) }>
                                    <td>
                                        {d.domain}
                                    </td>
                                    <td>
                                        {d.selector}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            
            { 
                selected &&
                <>
                    <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" size="xl" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${selected.domain}`}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{ minHeight: '70vh', overflowY: 'auto' }} >
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Domain</Form.Label>
                                        <Form.Control value={selected.domain} disabled={selected.id !== -1} onChange={e => setSelected({...selected, domain: e.target.value })} onBlur={e => updateDNSText(e)} ref={domainRef} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Selector</Form.Label>
                                        <Form.Control value={selected.selector} disabled={selected.id !== -1} onChange={e => setSelected({...selected, selector: e.target.value })} onBlur={e => updateDNSText(e)} ref={selectorRef} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Fingerprint</Form.Label>
                                        <Form.Label className="d-block">{selected.fingerprint}</Form.Label>
                                    </Form.Group>
                                </Form.Row>

                                <h4>DNS-Einstellungen</h4>
                                <Form.Group as={Row} className="mr-1">
                                    <Form.Label className="font-weight-bold col-form-label col-sm-1">Name</Form.Label>
                                    <Form.Label className="col-sm-11 col-form-label">{selected.dnsTxt.name}</Form.Label>
                                </Form.Group>
                                <Form.Group as={Row} className="mr-1">
                                    <Form.Label className="font-weight-bold col-form-label col-md-1">Wert</Form.Label>
                                    <Form.Label className="col-md-11 col-form-label text-wrap text-break">{selected.dnsTxt.value}</Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="danger" onClick={() => { setShowModal(false); setShowDelete(true); }}>Löschen</Button>
                            <Button variant="secondary" onClick={ () => setShowModal(false)}>Schließen</Button>
                            <Button onClick={ saveChanges }>Speichern</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDelete} onHide={() => setShowDelete(false)} backdrop="static" centered>
                        <Modal.Body as={Alert} variant="warning" className="m-0">
                            <h4 className="alert-heading">{`${selected.domain} löschen?`}</h4>
                            <p>Wenn Sie diesen Domain Key löschen, kann es zu Authentifizierungs- und Zustellproblemen kommen.</p>
                            <p><b>Dieser Vorgang kann nicht rückgängig gemacht werden!</b></p>
                            <hr />
                            <p className="d-flex justify-content-end m-0">
                                <Button variant="secondary-outline" onClick={() => { setShowDelete(false); setShowModal(true); }}>Abbrechen</Button>
                                <Button variant="danger" onClick={deleteDKIM}>Löschen</Button>
                            </p>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}
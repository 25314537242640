import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import { useApi } from '../api/WildduckApi'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const history = useHistory();

    const { auth: api_auth, logout: api_logout, requestUserInfo } = useApi();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        if (process.env.NODE_ENV === 'production' && token !== null && user !== null) {
            requestUserInfo(user, token).then(
                result => {
                    setCurrentUser({
                        address: result.data.address,
                        username: result.data.username,
                        name: result.data.name,
                        token: token,
                        id: user
                    });

                    history.push('/users');
                }
            );
        }

        if (process.env.NODE_ENV === 'development') {
            setCurrentUser({
                address: 'admin@easyrd.de',
                username: 'admin',
                name: 'Carsten Ellieroth',
                token: process.env.REACT_APP_ACCESS_TOKEN,
                id: '606d896318aa730510bba8e2'
            });
            history.push("/users");
        }
    }, [])

    function login(username, password) {
        return api_auth(username, password).then( async (result) => {
            const {scope, success, ...data} = result.data;

            localStorage.setItem("token", data.token);
            localStorage.setItem("user", data.id);

            await requestUserInfo(data.id, data.token).then(
                (result) => {
                    setCurrentUser({...data, name: result.data.name, address: result.data.address});
                }
            )
        });
    }

    function logout() {
        const { token } = currentUser;

        localStorage.removeItem("token");
        localStorage.removeItem("user");

        api_logout(token);
        setCurrentUser(null);
    }

    const value = {
        currentUser: currentUser,
        login: login,
        logout: logout
    }

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    )
}
